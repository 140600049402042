import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/home-hero"
import Helmet from "react-helmet"

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default function FourOFour(props) {
  return (
    <>
      <Helmet title={props.data.site.siteMetadata.title} />
      <h1 style={{fontSize: "4rem"}}>404 - Page not found</h1>
    </>
  )
}